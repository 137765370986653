export const GA_LABEL_LOADED_SIGNUP_PAGE = 'Signup: Loaded Signup Page'
export const GA_LABEL_ENTERED_FIRST_NAME = 'Signup: Entered First Name'
export const GA_LABEL_ENTERED_EMAIL_ADDRESS = 'Signup: Entered Email Address'
export const GA_LABEL_ENTERED_PASSWORD = 'Signup: Entered Password'
export const GA_LABEL_CLICKED_CREATE_ACCOUNT = 'Signup: Clicked Create Account'
export const GA_LABEL_CLICKED_CREATE_ACCOUNT_APPLE = 'Signup: Clicked Create Account Apple'
export const GA_LABEL_CLICKED_CREATE_ACCOUNT_GOOGLE = 'Signup: Clicked Create Account Google'

export const GA_LABEL_TRIGGERED_CREATE_ACCOUNT = 'Signup: Triggered Create Account'

export const GA_LABEL_CREATED_ACCOUNT = 'Signup: Created Account'

export const GA_LABEL_TRIGGERED_CREATE_SUBSCRIPTION = 'Triggered Create Subscription'
export const GA_LABEL_CREATED_SUBSCRIPTION = 'Created Subscription'
export const GA_LABEL_CANCELED_SUBSCRIPTION = 'Canceled Subscription'
export const GA_LABEL_CHECKOUT_OPENED_SUBSCRIPTION_MODAL = 'Checkout Opened Subscription Modal'
export const GA_LABEL_CHECKOUT_OPENED_SUBSCRIPTION_MODAL_DEEPLINK = 'Checkout Opened Subscription Modal: Deeplink'
export const GA_LABEL_CHECKOUT_CLOSED_SUBSCRIPTION_MODAL = 'Checkout Closed Subscription Modal'
export const GA_LABEL_CHECKOUT_OPENED_PROGRAM_GUARANTEE_MODAL = 'Checkout Opened Subscription Program Guarantee Modal'
export const GA_LABEL_CHECKOUT_SELECTED_PRODUCT = 'Checkout Selected Product'
export const GA_LABEL_CHECKOUT_APPROVED_PRODUCT = 'Checkout Approved Product'
export const GA_LABEL_CHECKOUT_CONSUMED_PRODUCT = 'Checkout Consumed Product'

export const GA_LABEL_TAPPED_CONFIRM_OPT_IN_FREE_TRIAL = 'Tapped Confirm Opt-In Free Trial'
export const GA_LABEL_COMPLETED_CONFIRM_OPT_IN_FREE_TRIAL = 'Completed Confirm Opt-In Free Trial'

export const GA_LABEL_USER_VALIDATED_LOGIN_EMAIL = 'User Validated Login Email'
export const GA_LABEL_USER_LOGGED_IN = 'User Logged In'
export const GA_LABEL_USER_LOGGED_OUT = 'User Logged Out'
export const GA_LABEL_USER_REQUESTED_ACCOUNT_DELETION = 'User Requested Account Deletion'
export const GA_LABEL_ENABLED_FREE_PREMIUM_ACCESS = 'Enabled Free Premium Access'

export const GA_LABEL_REGISTERED_NOTIFICATIONS_TRUE = 'Notification Registration Complete: True'
export const GA_LABEL_REGISTERED_NOTIFICATIONS_FALSE = 'Notification Registration Complete: False'

export const GA_LABEL_REQUESTED_APP_REVIEW = 'Requested App Review'

export const GA_LABEL_ONBOARDING_MODAL_COMPLETION = 'Onboarding Modal Completion: '
export const GA_LABEL_OPENED_INTRO_MODAL = 'Opened Onboarding Modal'
export const GA_LABEL_CLOSED_INTRO_MODAL = 'Closed Onboarding Modal'
export const GA_LABEL_COMPLETED_ONBOARDING = 'Completed Onboarding Modal'

export const GA_LABEL_OPENED_SELF_CHECK_IN_MODAL_DAILY_PLAN_BUTTON = 'Self Check-In Modal: Opened Daily Plan Button'
export const GA_LABEL_OPENED_SELF_CHECK_IN_MODAL_APP_ACTION = 'Self Check-In Modal: Opened App Action'
export const GA_LABEL_OPENED_SELF_CHECK_IN_MODAL = 'Self Check-In Modal: Opened'
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_ONBOARDING_SCREEN = "Self Check-In Modal: Completed Onboarding Screen"
export const GA_LABEL_SELF_CHECK_IN_CONFIRMED_SIMPLE_DESIGN = "Self Check-In Modal: Confirmed Simple Design"
export const GA_LABEL_SELF_CHECK_IN_CONFIRMED_COACHING_EXPECTATIONS = "Self Check-In Modal: Confirmed Coaching Expectations"
export const GA_LABEL_SELF_CHECK_IN_CONFIRMED_TAKE_OWNERSHIP = "Self Check-In Modal: Confirmed Take Ownership"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_CURRENT_STATE_SCREEN = "Self Check-In Modal: Completed Current State Ring"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_LOVE_AND_RELATIONSHIP_SCREEN = "Self Check-In Modal: Completed Love & Relationship Ring"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_MONEY_AND_FINANCE_SCREEN = "Self Check-In Modal: Completed Money & Finance Ring"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_FAMILY_AND_FRIENDS_SCREEN = "Self Check-In Modal: Completed Family & Friends Ring"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_CAREER_AND_BUSINESS_SCREEN = "Self Check-In Modal: Completed Career & Business Ring"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_HEALTH_AND_WELL_BEING_SCREEN = "Self Check-In Modal: Completed Health & Well-Being Ring"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_PURPOSE_AND_MISSION_SCREEN = "Self Check-In Modal: Completed Purpose & Mission Ring"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_ENVIRONMENT_SCREEN = "Self Check-In Modal: Completed Environment Ring"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_EXERCISE_CHECKBOX = "Self Check-In Modal: Completed Exercise Checkbox"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_PLAY_CREATIVITY_CHECKBOX = "Self Check-In Modal: Completed Play/Creativity Checkbox"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_STILLNESS_CHECKBOX = "Self Check-In Modal: Completed Stillness Checkbox"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_BEDTIME_ROUTINE_CHECKBOX = "Self Check-In Modal: Completed Bedtime Routine Checkbox"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_AVOID_LATE_NIGHT_EATING_CHECKBOX = "Self Check-In Modal: Completed Avoid Late-Night Eating Checkbox"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_AVOID_ALCOHOL_SUBSTANCES_CHECKBOX = "Self Check-In Modal: Completed Avoid Alcohol+Substances Checkbox"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_AVOID_STRESSFUL_ONLINE_CONTENT_CHECKBOX = "Self Check-In Modal: Completed Avoid Stressful Online Content Checkbox"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_AVOID_SOCIAL_FATIGUE_CHECKBOX = "Self Check-In Modal: Completed Avoid Social Fatigue Checkbox"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_RATE_SLEEP_QUALITY_SLIDER = "Self Check-In Modal: Completed Rate Sleep Quality Slider"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_RATE_DIET_QUALITY_SLIDER = "Self Check-In Modal: Completed Rate Diet Quality Slider"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_RATE_WATER_INTAKE_QUALITY_SLIDER = "Self Check-In Modal: Completed Rate Water Quality Slider"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_RATE_OVERALL_PEACE_QUALITY_SLIDER = "Self Check-In Modal: Completed Rate Peace Quality Slider"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_NOTIFICATIONS_CHECKBOX = "Self Check-In Modal: Completed Notifications Checkbox"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_NOTIFICATIONS_CHECKBOX_CONFIRMED = "Self Check-In Modal: Confirmed Notifications Checkbox"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_NOTIFICATIONS_CHECKBOX_DECLINED = "Self Check-In Modal: Declined Notifications Checkbox"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_NOTIFICATIONS_CHECKBOX_MORNING_CHECK_IN_TIME = "Self Check-In Modal: Completed Notifications Morning Time"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_NOTIFICATIONS_CHECKBOX_EVENING_CHECK_IN_TIME = "Self Check-In Modal: Completed Notifications Evening Time"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_NOTIFICATIONS_CHECKBOX_SCREEN = "Self Check-In Modal: Completed Notifications Screen"

export const GA_LABEL_SELF_CHECK_IN_COMPLETED_CLEAR_NOTIFICATIONS_CHECKBOX = "Self Check-In Modal: Completed CLEAR Notifications Checkbox"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_CLEAR_NOTIFICATIONS_CHECKBOX_CONFIRMED = "Self Check-In Modal: Confirmed CLEAR Notifications Checkbox"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_CLEAR_NOTIFICATIONS_CHECKBOX_DECLINED = "Self Check-In Modal: Declined CLEAR Notifications Checkbox"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_CLEAR_NOTIFICATIONS_CHECKBOX_DAY_OF_WEEK = "Self Check-In Modal: Completed CLEAR Notifications Day of Week"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_CLEAR_NOTIFICATIONS_CHECKBOX_TIME_OF_DAY = "Self Check-In Modal: Completed CLEAR Notifications Time of Day"
export const GA_LABEL_SELF_CHECK_IN_COMPLETED_CLEAR_NOTIFICATIONS_CHECKBOX_SCREEN = "Self Check-In Modal: Completed CLEAR Notifications Screen"


export const GA_LABEL_SELF_CHECK_IN_COMPLETED_RESULTS_SCREEN = "Self Check-In Modal: Completed Results Screen"
export const GA_LABEL_COMPLETED_SAVE_SELF_CHECK_IN_RESULT = 'Self-Check-In Modal: Completed Modal'
export const GA_LABEL_SELF_CHECK_IN_RESULTS = 'Self-Check-In Modal: Results'

export const GA_LABEL_OPENED_MEASURE_HRV_MODAL_DAILY_PLAN_BUTTON = 'Measure HRV Modal: Opened Daily Plan Button'
export const GA_LABEL_OPENED_MEASURE_HRV_MODAL_BOTTOM_BUTTON = 'Measure HRV Modal: Opened Bottom Button'
export const GA_LABEL_OPENED_MEASURE_HRV_MODAL_APP_ACTION = 'Measure HRV Modal: Opened App Action'
export const GA_LABEL_OPENED_MEASURE_HRV_MODAL = 'Measure HRV Modal: Opened'
export const GA_LABEL_HRV_MEASUREMENT_COMPLETED_ONBOARDING_EXPLANATION = 'Measure HRV Modal: Onboarding Explanation Completed'
export const GA_LABEL_HRV_MEASUREMENT_COMPLETED_ONBOARDING_EXPLANATION_LEARN_HRV = 'Measure HRV Modal: Onboarding Clicked Learn HRV'
export const GA_LABEL_HRV_MEASUREMENT_TRIGGERED_ENABLE_CAMERA_ACCESS = 'Measure HRV Modal: Triggered Enable Camera Access'
export const GA_LABEL_HRV_MEASUREMENT_CONFIRMED_CAMERA_ACCESS = 'Measure HRV Modal: Confirmed Camera Access'
export const GA_LABEL_HRV_MEASUREMENT_DENIED_CAMERA_ACCESS = 'Measure HRV Modal: Denied Camera Access'
export const GA_LABEL_HRV_MEASUREMENT_COMPLETED_ONBOARDING_INSTRUCTIONS = 'Measure HRV Modal: Onboarding Instructions Completed'
export const GA_LABEL_HRV_MEASUREMENT_TRIGGERED_HRV_MEASUREMENT = "Measure HRV Modal: Triggered Measurement"
export const GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_25_PERCENT = "Measure HRV Modal: 25% Measurement Progress"
export const GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_50_PERCENT = "Measure HRV Modal: 50% Measurement Progress"
export const GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_75_PERCENT = "Measure HRV Modal: 75% Measurement Progress"
export const GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_100_PERCENT = "Measure HRV Modal: 100% Measurement Progress"
export const GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_COMPLETED = "Measure HRV Modal: Completed Measurement"
export const GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_ERROR = "Measure HRV Modal: Measurement Error"
export const GA_LABEL_HRV_MEASUREMENT_ANALYSIS_ERROR_SIGNAL_QUALITY = "Measure HRV Modal: Analysis Error Signal Quality"
export const GA_LABEL_HRV_MEASUREMENT_ANALYSIS_ERROR_NETWORK_CONNECTION = "Measure HRV Modal: Analysis Error Network Connection"
export const GA_LABEL_HRV_MEASUREMENT_ANALYSIS_RESTART_NETWORK_CONNECTION = "Measure HRV Modal: Restarted Analysis Network Connection"
export const GA_LABEL_HRV_MEASUREMENT_ANALYSIS_RESTART_SIGNAL_QUALITY = "Measure HRV Modal: Restarted Measurement Signal Quality"
export const GA_LABEL_HRV_MEASUREMENT_ANALYSIS_TRIGGERED = "Measure HRV Modal: Analysis Triggered"
export const GA_LABEL_HRV_MEASUREMENT_ANALYSIS_CREATED_UPLOAD_URL = "Measure HRV Modal: Analysis Created Data Upload URL"
export const GA_LABEL_HRV_MEASUREMENT_ANALYSIS_UPLOADED_HRV_DATA = "Measure HRV Modal: Analysis Uploaded HRV Data"
export const GA_LABEL_HRV_MEASUREMENT_ANALYSIS_CREATED_ANALYSIS_TASK = "Measure HRV Modal: Analysis Created Task"
export const GA_LABEL_HRV_MEASUREMENT_ANALYSIS_COMPLETED = "Measure HRV Modal: Analysis Completed"
export const GA_LABEL_HRV_MEASUREMENT_ANALYSIS_ERROR_UPLOAD_URL = "Measure HRV Modal: Analysis Error Create Upload URL"
export const GA_LABEL_HRV_MEASUREMENT_ANALYSIS_ERROR_UPLOAD_READING_DATA = "Measure HRV Modal: Analysis Error Upload HRV Data"
export const GA_LABEL_HRV_MEASUREMENT_ANALYSIS_ERROR_CREATE_ANALYSIS_TASK = "Measure HRV Modal: Analysis Error Create Task"
export const GA_LABEL_HRV_MEASUREMENT_COMPLETED = "Measure HRV Modal: Completed"
export const GA_LABEL_HRV_MEASUREMENT_HRV_RESULT_STATISTICS = 'Measure HRV Modal: Results'

export const GA_LABEL_OPENED_BALANCE_TRAINING_MODAL_DAILY_PLAN_BUTTON = 'Balance Training Modal: Opened Daily Plan Button'
export const GA_LABEL_OPENED_BALANCE_TRAINING_MODAL_BOTTOM_BUTTON = 'Balance Training Modal: Opened Bottom Button'
export const GA_LABEL_OPENED_BALANCE_TRAINING_MODAL_APP_ACTION = 'Balance Training Modal: Opened App Action'
export const GA_LABEL_OPENED_BALANCE_TRAINING_MODAL = 'Balance Training Modal: Opened'

export const GA_LABEL_BALANCE_TRAINING_MODAL_COMPLETED_ONBOARDING = "Balance Training Modal: Completed Onboarding"
export const GA_LABEL_BALANCE_TRAINING_MODAL_COMPLETED_ONBOARDING_EXPLANATION_LEARN_SOMATICS = "Balance Training Modal: Clicked Onboarding Learn Somatics"
export const GA_LABEL_BALANCE_TRAINING_MODAL_COMPLETED_PRE_EXERCISE_RING = "Balance Training Modal: Completed Pre-Exercise Ring"
export const GA_LABEL_BALANCE_TRAINING_MODAL_COMPLETED_PRE_EXERCISE_DESIRED_STATE = "Balance Training Modal: Completed Pre-Exercise Desired Ring"
export const GA_LABEL_BALANCE_TRAINING_MODAL_LOADED_RECOMMENDATIONS = "Balance Training Modal: Loaded Exercise Recommendations"
export const GA_LABEL_BALANCE_TRAINING_MODAL_SELECTED_EXERCISE_RECOMMENDATION = "Balance Training Modal: Selected Exercise Recommendation"
export const GA_LABEL_BALANCE_TRAINING_MODAL_STARTED_EXERCISE_TIMER = "Balance Training Modal: Started Exercise Timer"
export const GA_LABEL_BALANCE_TRAINING_MODAL_PAUSED_EXERCISE_TIMER = "Balance Training Modal: Paused Exercise Timer"
export const GA_LABEL_BALANCE_TRAINING_MODAL_CLICKED_COMPLETE_EXERCISE = "Balance Training Modal: Clicked Complete Exercise"
export const GA_LABEL_BALANCE_TRAINING_MODAL_COMPLETED_EXERCISE = "Balance Training Modal: Completed Exercise"
export const GA_LABEL_BALANCE_TRAINING_MODAL_COMPLETED_POST_EXERCISE_RING = "Balance Training Modal: Completed Post-Exercise Ring"
export const GA_LABEL_BALANCE_TRAINING_MODAL_COMPLETED_POST_EXERCISE_RING_SATISFACTORY = "Balance Training Modal: Post-Exercise Ring Satisfactory"
export const GA_LABEL_BALANCE_TRAINING_MODAL_COMPLETED_POST_EXERCISE_RING_UNSATISFACTORY = "Balance Training Modal: Post-Exercise Ring Unsatisfactory"
export const GA_LABEL_BALANCE_TRAINING_MODAL_CLICKED_SAVE_BUTTON = "Balance Training Modal: Clicked Save Results"
export const GA_LABEL_BALANCE_TRAINING_MODAL_SAVED_RESULTS = "Balance Training Modal: Completed"
export const GA_LABEL_COMPLETED_SAVE_EXERCISE_RESULT = 'Balance Training Modal: Completed'
export const GA_LABEL_BALANCE_TRAINING_MODAL_RESULT_STARTED_NEGATIVE = 'Balance Training Modal: Started Negative'
export const GA_LABEL_BALANCE_TRAINING_MODAL_RESULT_RELIEVED_NEGATIVITY = 'Balance Training Modal: Relieved Negativity'
export const GA_LABEL_BALANCE_TRAINING_MODAL_RESULT_SHIFTED_EXACT_MOOD = 'Balance Training Modal: Shifted Exact Mood'

export const GA_LABEL_BALANCE_TRAINING_MODAL_SHARED_BALANCE_EXERCISE = 'Balance Training Modal: Shared Exercise'
export const GA_LABEL_BALANCE_TRAINING_MODAL_UPDATED_BALANCE_EXERCISE_PREFERENCES = 'Balance Training Modal: Updated Exercise Preferences'

export const GA_LABEL_TOGGLED_HAPTICS = "Haptics Modal: Toggled Haptics"

export const GA_LABEL_OPENED_LEARN_ARTICLE_MODAL_DAILY_PLAN_BUTTON = 'Learn Modal: Opened Daily Plan Button'
export const GA_LABEL_OPENED_LEARN_MODAL_BOTTOM_BUTTON = 'Learn Modal: Opened Bottom Button'
export const GA_LABEL_OPENED_LEARN_MODAL_FREE_ONBOARDING = 'Learn Modal: Opened Free Onboarding'
export const GA_LABEL_OPENED_LEARN_MODAL_SETTINGS_BUTTON = 'Learn Modal: Opened Settings Button'
export const GA_LABEL_OPENED_LEARN_ARTICLE_DIRECT_LINK = 'Learn Modal: Opened Article Direct Link'
export const  GA_LABEL_OPENED_LEARN_ARTICLE_MODAL_APP_ACTION = 'Learn Modal: Opened App Action'
export const GA_LABEL_OPENED_LEARN_MODAL_ONBOARDING_HEADER = 'Learn Modal: Opened Onboarding Header'
export const GA_LABEL_OPENED_LEARN_MODAL = 'Learn Modal: Opened'

export const GA_LABEL_OPENED_SET_UP_CLEAR_MODAL_DAILY_PLAN_BUTTON = 'Clear Notifications Modal: Opened Daily Plan Button'
export const GA_LABEL_OPENED_CLEAR_JOURNALING_MODAL_BOTTOM_BUTTON = 'Clear Modal: Opened Bottom Button'
export const GA_LABEL_OPENED_CLEAR_JOURNALING_MODAL = 'Clear Modal: Opened'
export const GA_LABEL_OPENED_CLEAR_JOURNALING_MODAL_APP_ACTION = 'Clear Modal: Opened App Action'
export const GA_LABEL_CLEAR_JOURNALING_COMPLETED_ONBOARDING_EXPLANATION = 'Clear Modal: Completed Onboarding'
export const GA_LABEL_CLEAR_JOURNALING_COMPLETED_ONBOARDING_EXPLANATION_SOMATIC_JOURNALING = 'Clear Modal: Completed Somatic Journaling Explanation'
export const GA_LABEL_CLEAR_JOURNALING_SESSION_SELECTED_LIFE_ASPECT = 'Clear Modal: Selected Life Aspect'
export const GA_LABEL_CLEAR_JOURNALING_SESSION_SELECTED_CURRENT_STATE = 'Clear Modal: Selected Current State'
export const GA_LABEL_CLEAR_JOURNALING_SESSION_COMPLETED_CONVERSATION_TURN = 'Clear Modal: Completed Conversation Turn'
export const GA_LABEL_CLEAR_JOURNALING_SESSION_COMPLETED = 'Clear Modal: Session Completed'

export const GA_LABEL_LEARN_MODAL_LOADED_ARTICLE_RECOMMENDATIONS = 'Learn Modal: Loaded Article Recommendations'
export const GA_LABEL_LEARN_MODAL_COMPLETED_ARTICLE_SEARCH = 'Learn Modal: Completed Article Search'
export const GA_LABEL_LEARN_MODAL_SELECTED_ARTICLE = 'Learn Modal: Selected Article'
export const GA_LABEL_LEARN_MODAL_LOADED_ARTICLE_METADATA = 'Learn Modal: Loaded Article Metadata'
export const GA_LABEL_LEARN_MODAL_COMPLETED_START_ARTICLE = 'Learn Modal: Completed Start Article'
export const GA_LABEL_LEARN_MODAL_COMPLETED_READ_ARTICLE = 'Learn Modal: Completed Read Article'
export const GA_LABEL_LEARN_MODAL_COMPLETED_RATE_ARTICLE = 'Learn Modal: Completed Rate Article'
export const GA_LABEL_LEARN_MODAL_COMPLETED_SHARE_ARTICLE = 'Learn Modal: Completed Share Article'
export const GA_LABEL_LEARN_MODAL_COMPLETED_ONBOARDING_ARTICLE = 'Learn Modal: Completed Onboarding Article'

export const GA_LABEL_INSIGHTS_KEY_TAKEAWAYS_MEASUREMENT = "Insights Key Takeaways Result"
export const GA_LABEL_PERSONALIZED_ONBOARDING_PREFERENCES = "Personalized Onboarding Preferences"

export const GA_LABEL_RATED_APP_EXPERIENCE = "Rate App Experience: Tapped Star Rating"
export const GA_LABEL_RATE_APP_EXPERIENCE_CLICKED_APP_STORE_BUTTON = "Rate App Experience: Clicked App Store Button"
export const GA_LABEL_RATE_APP_EXPERIENCE_CLICKED_FEEDBACK_BUTTON = "Rate App Experience: Clicked Feedback Button"
export const GA_LABEL_RATE_APP_EXPERIENCE_CLOSED_PANEL = "Rate App Experience: Clicked Close Button"


export const GA_LABEL_OPENED_DISCOVERY_QUIZ_MODAL_DEEPLINK = 'Quiz Page: Opened Modal Deeplink'
export const GA_LABEL_OPENED_DISCOVERY_QUIZ_MODAL = 'Quiz Page: Opened Modal'
export const GA_LABEL_OPENED_QUIZ_MODAL_FREE_ONBOARDING = 'Quiz Page: Opened Modal Free Onboarding'
export const GA_LABEL_QUIZ_OPENED_PAGE = 'Quiz Page: Loaded Page'
export const GA_LABEL_QUIZ_COMPLETED_ONBOARDING_SCREEN = 'Quiz Page: Completed Onboarding Screen'
export const GA_LABEL_QUIZ_COMPLETED_DIGESTIVE_ISSUES_CHECKBOX = 'Quiz Page: Completed Digestive Issues Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_PERSISTENT_PAIN_CHECKBOX = 'Quiz Page: Completed Persistent Pain Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_FREQUENT_SICKNESS_CHECKBOX = 'Quiz Page: Completed Frequent Sickness Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_FREQUENT_IMMUNE_ISSUES_CHECKBOX = 'Quiz Page: Completed Frequent Immune Issues Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_BRAIN_FOG_CHECKBOX = 'Quiz Page: Completed Brain Fog Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_FREQUENT_FATIGUE_CHECKBOX = 'Quiz Page: Completed Frequent Fatigue Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_DIFFICULTY_RELAXING_CHECKBOX = 'Quiz Page: Completed Difficulty Relaxing Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_DIFFICULTY_CONTROLLING_EMOTIONS_CHECKBOX = 'Quiz Page: Completed Controlling Emotions Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_FREQUENT_ANXIETY_STRESS_CHECKBOX = 'Quiz Page: Completed Frequent Anxiety Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_FREQUENTLY_IRRITABLE_ON_EDGE_CHECKBOX = 'Quiz Page: Completed Frequent Irritability Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_EXERCISE_CHECKBOX = 'Quiz Page: Completed Exercise Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_PLAY_CREATIVITY_CHECKBOX = 'Quiz Page: Completed Play/Creativity Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_STILLNESS_CHECKBOX = 'Quiz Page: Completed Stillness Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_BEDTIME_ROUTINE_CHECKBOX = 'Quiz Page: Completed Bedtime Routine Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_AVOID_LATE_NIGHT_EATING_CHECKBOX = 'Quiz Page: Completed Avoid Late-Night Eating Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_AVOID_ALCOHOL_SUBSTANCES_CHECKBOX = 'Quiz Page: Completed Avoid Alcohol Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_AVOID_STRESSFUL_ONLINE_CONTENT_CHECKBOX = 'Quiz Page: Completed Avoid Online Stress Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_AVOID_SOCIAL_FATIGUE_CHECKBOX = 'Quiz Page: Completed Avoid Social Fatigue Checkbox'
export const GA_LABEL_QUIZ_COMPLETED_RATE_SLEEP_QUALITY_SLIDER = 'Quiz Page: Completed Sleep Quality Slider'
export const GA_LABEL_QUIZ_COMPLETED_RATE_DIET_QUALITY_SLIDER = 'Quiz Page: Completed Diet Quality Slider'
export const GA_LABEL_QUIZ_COMPLETED_RATE_WATER_INTAKE_QUALITY_SLIDER = 'Quiz Page: Completed Water Intake Slider'
export const GA_LABEL_QUIZ_COMPLETED_RATE_OVERALL_PEACE_QUALITY_SLIDER = 'Quiz Page: Completed Overall Peace Slider'
export const GA_LABEL_QUIZ_COMPLETED_CURRENT_STATE_SCREEN = 'Quiz Page: Completed Current State'
export const GA_LABEL_QUIZ_COMPLETED_LOVE_AND_RELATIONSHIP_SCREEN = 'Quiz Page: Completed Love & Relationship'
export const GA_LABEL_QUIZ_COMPLETED_MONEY_AND_FINANCE_SCREEN = 'Quiz Page: Completed Money & Finance'
export const GA_LABEL_QUIZ_COMPLETED_FAMILY_AND_FRIENDS_SCREEN = 'Quiz Page: Completed Family & Friends'
export const GA_LABEL_QUIZ_COMPLETED_CAREER_AND_BUSINESS_SCREEN = 'Quiz Page: Completed Career & Business'
export const GA_LABEL_QUIZ_COMPLETED_HEALTH_AND_WELL_BEING_SCREEN = 'Quiz Page: Completed Health & Well-Being'
export const GA_LABEL_QUIZ_COMPLETED_PURPOSE_AND_MISSION_SCREEN = 'Quiz Page: Completed Purpose & Mission'
export const GA_LABEL_QUIZ_COMPLETED_ENVIRONMENT_SCREEN = 'Quiz Page: Completed Environment'
export const GA_LABEL_QUIZ_ENTERED_FIRST_NAME = 'Quiz Page: Entered First Name'
export const GA_LABEL_QUIZ_ENTERED_EMAIL_ADDRESS = 'Quiz Page: Entered Email Address'
export const GA_LABEL_QUIZ_TRIGGERED_SAVE_RESULTS = 'Quiz Page: Triggered Save Results'
export const GA_LABEL_QUIZ_COMPLETED_SAVE_RESULTS = 'Quiz Page: Saved Results'
export const GA_LABEL_QUIZ_RESULTS = 'Quiz Page: Results'
export const GA_LABEL_QUIZ_CLICKED_DOWNLOAD_APP_BUTTON = 'Quiz Page: Clicked Download App Button'
export const GA_LABEL_QUIZ_CLICKED_COACH_PROFILE_BUTTON = 'Quiz Page: Clicked Coach Profile Button'

export const GA_LABEL_OPENED_PERSONALIZE_PREFERENCES_MODAL = 'Personalize Preferences Modal: Opened Modal'
export const GA_LABEL_PERSONALIZE_PREFERENCES_SELECTED_GOAL = 'Personalize Preferences Modal: Selected Goal'
export const GA_LABEL_PERSONALIZE_PREFERENCES_SELECTED_LIFE_ASPECT = 'Personalize Preferences Modal: Selected Life Aspect'
export const GA_LABEL_PERSONALIZE_PREFERENCES_SELECTED_LIFE_ASPECT_CURRENT_STATE = 'Personalize Preferences Modal: Selected Life Aspect Current State'
export const GA_LABEL_PERSONALIZE_PREFERENCES_SELECTED_LIFE_ASPECT_PREFERRED_STATE = 'Personalize Preferences Modal: Selected Life Aspect Preferred State'
export const GA_LABEL_PERSONALIZE_PREFERENCES_CONFIRMED_SIMPLE_DESIGN = 'Personalize Preferences Modal: Confirmed Simple Design'
export const GA_LABEL_PERSONALIZE_PREFERENCES_CONFIRMED_COACHING_EXPECTATIONS = 'Personalize Preferences Modal: Confirmed Coaching Expectations'
export const GA_LABEL_PERSONALIZE_PREFERENCES_CONFIRMED_TAKE_OWNERSHIP = 'Personalize Preferences Modal: Confirmed Take Ownership'
export const GA_LABEL_PERSONALIZE_PREFERENCES_TRIGGERED_SAVE_RESULTS = 'Personalize Preferences Modal: Triggered Save Results'
export const GA_LABEL_PERSONALIZE_PREFERENCES_COMPLETED = 'Personalize Preferences Modal: Completed'


export const GA_LABEL_OPENED_PRODUCT_ONBOARDING_MODAL_BOTTOM_SECTION = "Product Onboarding Modal: Opened Modal Bottom Section"
export const GA_LABEL_OPENED_PRODUCT_ONBOARDING_MODAL = "Product Onboarding Modal: Opened Modal"
export const GA_LABEL_PRODUCT_ONBOARDING_COMPLETED_SELECT_ICON = "Product Onboarding Modal: Completed Select Icon"
export const GA_LABEL_PRODUCT_ONBOARDING_COMPLETED_DESIGN = "Product Onboarding Modal: Completed Design"
export const GA_LABEL_PRODUCT_ONBOARDING_COMPLETED_CHECKIN = "Product Onboarding Modal: Completed CHECK-IN"
export const GA_LABEL_PRODUCT_ONBOARDING_COMPLETED_MEASURE = "Product Onboarding Modal: Completed MEASURE"
export const GA_LABEL_PRODUCT_ONBOARDING_COMPLETED_KEY_TAKEAWAYS = "Product Onboarding Modal: Completed Key Takeaways"
export const GA_LABEL_PRODUCT_ONBOARDING_COMPLETED_BALANCE = "Product Onboarding Modal: Completed BALANCE"
export const GA_LABEL_PRODUCT_ONBOARDING_COMPLETED_CLEAR = "Product Onboarding Modal: Completed CLEAR"
export const GA_LABEL_PRODUCT_ONBOARDING_COMPLETED_LEARN = "Product Onboarding Modal: Completed LEARN"
export const GA_LABEL_PRODUCT_ONBOARDING_COMPLETED_RESULTS = "Product Onboarding Modal: Completed Results"
export const GA_LABEL_PRODUCT_ONBOARDING_COMPLETED = "Product Onboarding Modal: Completed"

export const GA_LABEL_SELECTED_APP_ICON = "Selected App Icon"

export const GA_LABEL_CLICKED_AFFILIATE_SIGNUP_LINK = 'Clicked Affiliate Signup Link'

export const GA_LABEL_LOADED_LANDING_PAGE = "Landing Page: Loaded Landing Page"
export const GA_LABEL_CLICKED_APP_DOWNLOAD_LINK = "Landing Page: Clicked App Download Link"
export const GA_LABEL_CLICKED_WORKSHOP_BUTTON = "Landing Page: Clicked Workshop Button"
export const GA_LABEL_CLICKED_COURSE_BUTTON = "Landing Page: Clicked Course Button"
export const GA_LABEL_CLICKED_CERTIFICATION_BUTTON = "Landing Page: Clicked Certification Button"
export const GA_LABEL_CLICKED_COACH_OS_BUTTON = "Landing Page: Clicked Coach OS Button"
export const GA_LABEL_CLICKED_TEAMS_BUTTON = "Landing Page: Clicked Teams Button"
export const GA_LABEL_CLICKED_BLOG_BUTTON = "Landing Page: Clicked Blog Button"
export const GA_LABEL_CLICKED_LEARN_BUTTON = "Landing Page: Clicked Learn Button"
export const GA_LABEL_CLICKED_ABOUT_BUTTON = "Landing Page: Clicked About Button"
export const GA_LABEL_CLICKED_QUIZ_BUTTON = "Landing Page: Clicked Quiz Button"
export const GA_LABEL_CLICKED_N_PLUS_BUTTON = "Landing Page: Clicked N+ Button"

export const GA_LABEL_CLICKED_APP_AFFILIATE_LINK = "Coach Profile Page: Clicked App Affiliate Link"
export const GA_LABEL_CLICKED_CERTIFICATION_AFFILIATE_LINK = "Coach Profile Page: Clicked Cert Affiliate Link"
export const GA_LABEL_CLICKED_COACH_DISCOVERY_QUIZ_LINK = "Coach Profile Page: Clicked Discovery Quiz Link"

export const GA_LABEL_LOADED_BALANCE_PAGE = 'BALANCE Page: Loaded BALANCE Page'
export const GA_LABEL_BALANCE_PAGE_CLICKED_PERSONALIZE_TRAINING = 'BALANCE Page: Clicked Personalize Training'
export const GA_LABEL_BALANCE_PAGE_SELECTED_ENERGY_TYPE = 'BALANCE Page: Selected Energy Type'
export const GA_LABEL_BALANCE_PAGE_COMPLETED_RATE_CURRENT_STRESS_SLIDER = 'BALANCE Page: Completed Rate Pre-Exercise Stress Level'
export const GA_LABEL_BALANCE_PAGE_COMPLETED_PRE_EXERCISE_RING = 'BALANCE Page: Completed Pre-Exercise State'
export const GA_LABEL_BALANCE_PAGE_COMPLETED_PRE_EXERCISE_DESIRED_STATE = 'BALANCE Page: Completed Pre-Exercise Desired State'
export const GA_LABEL_BALANCE_PAGE_STARTED_EXERCISE_TIMER = 'BALANCE Page: Started Exercise Timer'
export const GA_LABEL_BALANCE_PAGE_PAUSED_EXERCISE_TIMER = 'BALANCE Page: Paused Exercise Timer'
export const GA_LABEL_BALANCE_PAGE_COMPLETED_EXERCISE = 'BALANCE Page: Completed Exercise'
export const GA_LABEL_BALANCE_PAGE_COMPLETED_RATE_POST_EXERCISE_STRESS_SLIDER = 'BALANCE Page: Completed Post-Exercise Stress Level'
export const GA_LABEL_BALANCE_PAGE_CLICKED_POST_EXERCISE_DOWNLOAD_APP = 'BALANCE Page: Clicked Download App'
export const GA_LABEL_BALANCE_PAGE_CLICKED_POST_EXERCISE_NERVOUS_SYSTEM_CERTIFICATION = 'BALANCE Page: Clicked Nervous System Certification'
export const GA_LABEL_BALANCE_PAGE_CLICKED_POST_EXERCISE_SHARE_TOOL = 'BALANCE Page: Clicked Share Tool'
export const GA_LABEL_BALANCE_PAGE_CLICKED_POST_EXERCISE_BUSINESS_INQUIRIES = 'BALANCE Page: Clicked Business Inquiries'
export const GA_LABEL_BALANCE_PAGE_CLICKED_POST_EXERCISE_MEDIA_INQUIRIES = 'BALANCE Page: Clicked Media Inquiries'

export const GA_LABEL_LOADED_COURSE_PAGE = "Course Page: Loaded Course Page"
export const GA_LABEL_CLICKED_COURSE_CHECKOUT_BUTTON = "Course Page: Clicked Checkout Button"
export const GA_LABEL_COURSE_PURCHASE_TRIGGERED = "Course Page: Purchase Triggered"
export const GA_LABEL_COURSE_PURCHASE_COMPLETED = "Course Page: Purchase Completed"

export const GA_LABEL_COACH_SESSION_PURCHASE_TRIGGERED = "Coach Page: Purchase Triggered"
export const GA_LABEL_COACH_SESSION_PURCHASE_COMPLETED = "Coach Page: Purchase Completed"

export const GA_LABEL_LOADED_MASTERCLASS_PAGE = "Masterclass Page: Loaded Masterclass Page"
export const GA_LABEL_CLICKED_MASTERCLASS_CHECKOUT_BUTTON = "Masterclass Page: Clicked Checkout Button"
export const GA_LABEL_MASTERCLASS_PURCHASE_TRIGGERED = "Masterclass Page: Purchase Triggered"
export const GA_LABEL_MASTERCLASS_PURCHASE_COMPLETED = "Masterclass Page: Purchase Completed"

export const GA_LABEL_LOADED_LIVE_CLASS_PAGE = "Live Class Page: Loaded Page"
export const GA_LABEL_CLICKED_LIVE_CLASS_CHECKOUT_BUTTON = "Live Class Page: Clicked Checkout Button"

export const GA_LABEL_LOADED_NPLUS_PAGE = "NPlus Page: Loaded Page"
export const GA_LABEL_NPLUS_CLICKED_CONTACT_BUTTON = "NPlus Page: Clicked Contact Button"
export const GA_LABEL_NPLUS_TRIGGERED_SUBMIT_CONTACT_FORM = "NPlus Page: Triggered Contact Form"
export const GA_LABEL_NPLUS_COMPLETED_SUBMIT_CONTACT_FORM = "NPlus Page: Completed Contact Form"
export const GA_LABEL_NPLUS_PURCHASE_TRIGGERED = "NPlus Page: Purchase Triggered"
export const GA_LABEL_NPLUS_PURCHASE_COMPLETED = "NPlus Page: Purchase Completed"

export const GA_LABEL_LOADED_CERTIFICATION_PAGE = "Certification Page: Loaded Certification Page"
export const GA_LABEL_CLICKED_CERTIFICATION_CHECKOUT_BUTTON = "Certification Page: Clicked Checkout Button"
export const GA_LABEL_CERTIFICATION_ENTERED_EXPIRING_DISCOUNT_CODE = "Certification Page: Entered Expiring Discount Code"
export const GA_LABEL_CERTIFICATION_CLICKED_PAYMENT_PLAN = "Certification Page: Clicked Payment Plan Button"
export const GA_LABEL_CERTIFICATION_PURCHASE_TRIGGERED = "Certification Page: Purchase Triggered"
export const GA_LABEL_CERTIFICATION_PURCHASE_COMPLETED = "Certification Page: Purchase Completed"
export const GA_LABEL_CLICKED_CERTIFICATION_CHECKOUT_TWO_PAYMENTS_BUTTON = "Certification Page: Clicked Checkout 2 Payments Button"
export const GA_LABEL_STARTED_CERTIFICATION_LOREN_VIDEO = "Certification Page: Started Loren Video"
export const GA_LABEL_COMPLETED_CERTIFICATION_LOREN_VIDEO = "Certification Page: Completed Loren Video"
export const GA_LABEL_CLICKED_BOOK_CERTIFICATION_CALL_LINK = "Certification Page: Clicked Book Call"
export const GA_LABEL_CLICKED_VSL_BUTTON = "Certification Page: Clicked Watch VSL Button"
export const GA_LABEL_CLICKED_CERTIFICATION_TESTIMONIAL_CATEGORY_BUTTON = "Certification Page: Selected Testimonial Category"
export const GA_LABEL_OPENED_CERTIFICATION_DEEPLINK = "Certification Page: Opened Deeplink"
export const GA_LABEL_CLICKED_CERTIFICATION_INDUSTRY_CATEGORY_BUTTON = "Certification Page: Selected Industry Category"
export const GA_LABEL_CLICKED_CERTIFICATION_CONTACT_INSTAGRAM_BUTTON = "Certification Page: Clicked Contact Instagram"
export const GA_LABEL_CLICKED_CERTIFICATION_CONTACT_EMAIL_BUTTON = "Certification Page: Clicked Contact Email"
export const GA_LABEL_CLICKED_CERTIFICATION_CONTACT_WHATSAPP_BUTTON = "Certification Page: Clicked Contact WhatsApp"

export const GA_LABEL_LOADED_WALL_OF_LOVE_PAGE = "Wall Of Love Page: Loaded Page"
export const GA_LABEL_CLICKED_APP_REVIEW_CATEGORY_BUTTON = "Wall Of Love Page: Selected Review Category"

export const GA_LABEL_LOADED_RELIEF_PAGE = "Relief Page: Loaded Page"
export const GA_LABEL_RELIEF_PAGE_VIEWED_REGISTRATION_FORM = "Relief Page: Viewed Form"
export const GA_LABEL_RELIEF_PAGE_COMPLETED_REGISTRATION_FORM = "Relief Page: Completed Form"
export const GA_LABEL_LOADED_RELIEF_CONFIRMATION_PAGE = "Relief Confirmation Page: Loaded Page"
export const GA_LABEL_RELIEF_CONFIRMATION_PAGE_CLICKED_DOWNLOAD_APP = "Relief Confirmation Page: Clicked Download App"

export const GA_LABEL_CLICKED_MASTER_COACH_PRESALE_PAY_IN_FULL = "Master Coach Page: Clicked Checkout Pay In Full Button"
export const GA_LABEL_CLICKED_MASTER_COACH_PRESALE_PAYMENT_PLAN = "Master Coach Page: Clicked Checkout Payment Plan Button"

export const GA_LABEL_LOADED_MASTER_COACH_WAITLIST_PAGE = "Master Coach Waitlist Page: Loaded Page"
export const GA_LABEL_CLICKED_REGISTER_FOR_MASTER_COACH_WAITLIST = "Master Coach Waitlist Page: Clicked Register"

export const GA_LABEL_PLATFORM_PAGE_LOADED_PAGE = "Platform Page: Loaded Page"
export const GA_LABEL_PLATFORM_PAGE_ENTERED_EXPIRING_DISCOUNT_CODE = "Platform Page: Entered Expiring Discount Code"
export const GA_LABEL_PLATFORM_PAGE_CLICKED_PAYMENT_PLAN = "Platform Page: Clicked Payment Plan Button"
export const GA_LABEL_PLATFORM_PAGE_PURCHASE_TRIGGERED = "Platform Page: Purchase Triggered"
export const GA_LABEL_PLATFORM_PAGE_PURCHASE_COMPLETED = "Platform Page: Purchase Completed"
export const GA_LABEL_PLATFORM_PAGE_CLICKED_TESTIMONIAL_CATEGORY_BUTTON = "Platform Page: Selected Testimonial Category"
export const GA_LABEL_PLATFORM_PAGE_OPENED_DEEPLINK = "Platform Page: Opened Deeplink"
export const GA_LABEL_PLATFORM_PAGE_CLICKED_INDUSTRY_CATEGORY_BUTTON = "Platform Page: Selected Industry Category"

export const GA_LABEL_LOADED_APP_CHECKOUT_PAGE = "App Checkout Page: Loaded Page"
export const GA_LABEL_APP_CHECKOUT_CLICKED_MEMBERSHIP_PLAN = "App Checkout Page: Clicked Membership Plan"
export const GA_LABEL_APP_CHECKOUT_PURCHASE_TRIGGERED = "App Checkout Page: Purchase Triggered"
export const GA_LABEL_APP_CHECKOUT_PURCHASE_COMPLETED = "App Checkout Page: Purchase Completed"

export const GA_LABEL_APP_ACCESS_PURCHASE_TRIGGERED = "Trainer Dashboard: App Pre-Purchase Triggered"
export const GA_LABEL_APP_ACCESS_PURCHASE_COMPLETED = "Trainer Dashboard: App Pre-Purchase Completed"

export const GA_LABEL_CLICKED_REDIRECT_TO_COURSE_CHECKOUT_PAGE = "Certification Page: Redirect Course Checkout"
export const GA_LABEL_CLICKED_REDIRECT_TO_CERTIFICATION_CHECKOUT_PAGE = "Certification Page: Redirect Certification Checkout"

export const GA_LABEL_LOADED_PURCHASE_CERTIFICATION_PAGE = 'Certification Page: Loaded Checkout Page'
export const GA_LABEL_LOADED_PURCHASE_COURSE_PAGE = 'Course Page: Loaded Checkout Page'

export const GA_LABEL_LOADED_CERTIFICATION_VIDEO_PAGE = "Certification Video Page: Loaded Page"
export const GA_LABEL_CLICKED_REGISTER_FOR_CERTIFICATION_TRAINING = "Certification Video Page: Clicked Register"
export const GA_LABEL_CERTIFICATION_OPT_IN_PAGE_SCROLLED_DOWN = "Certification Video Page: Scrolled Down"
export const GA_LABEL_CERTIFICATION_HERO_BUTTON_CLICK = "Certification Video Page: Hero Button Click"
export const GA_LABEL_CERTIFICATION_OPT_IN_COMPLETED = "Certification Video Page: Opt In Completed"
export const GA_LABEL_CERTIFICATION_STARTED_VIDEO = "Certification Video Page: Started Video"

export const GA_LABEL_LOADED_CERTIFICATION_VSL_PAGE = "Certification VSL Page: Loaded Page"
export const GA_LABEL_LOADED_CERTIFICATION_VSL_CLICKED_START_VIDEO = "Certification VSL Page: Clicked Start Video"
export const GA_LABEL_LOADED_CERTIFICATION_VSL_CLICKED_ENROLL_NOW = "Certification VSL Page: Clicked Enroll Now"

export const GA_LABEL_LOADED_REGISTER_LIVE_TRAINING_PAGE = "Live Training Page: Loaded Page"
export const GA_LABEL_CLICKED_REGISTER_FOR_LIVE_TRAINING = "Live Training Page: Clicked Register"
export const GA_LABEL_REGISTER_LIVE_TRAINING_OPT_IN_COMPLETED = "Live Training Page: Opt In Completed"

export const GA_LABEL_TEAMS_LOADED_PAGE = "Teams Page: Loaded Page"
export const GA_LABEL_TEAMS_CLICKED_REGISTER_FOR_TEAMS = "Teams Page: Clicked Register For Teams"
export const GA_LABEL_TEAMS_CLICKED_CERTIFICATION = "Teams Page: Clicked Certification"
export const GA_LABEL_TEAMS_CLICKED_REPORT = "Teams Page: Clicked Download Report"

export const GA_LABEL_APP_STORE_AD_LINK_CLICK = 'App Store Ad: Verified Link Click'
export const GA_LABEL_APP_STORE_AD_LINK_CLICK_CONVERSION = 'App Store Ad: App Store Conversion'

export const GA_LABEL_MANAGE_BILLING_MODAL_OPENED = 'Manage Billing Modal: Opened Modal'
export const GA_LABEL_MANAGE_BILLING_MODAL_CLICKED_MANAGE_SUBSCRIPTION = 'Manage Billing Modal: Clicked Manage Subscription'

